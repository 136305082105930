import React from 'react';
import {
  View,
  NativeSyntheticEvent,
  TextInputChangeEventData,
  Pressable,
  Keyboard,
  Platform,
} from 'react-native';
import { makeStyles, useTheme } from '../../theme';
import { Text } from '../text';
import { BottomSheetModal } from '@gorhom/bottom-sheet';
import { BottomSheet } from '../bottom-sheet';
import { TypeaheadNativeBase } from './TypeaheadNativeBase';
import { uniqueWithMap } from './utils';
import { TypeaheadContext } from './context';
import { TypeaheadService } from './services';
import { TypeaheadBaseItem, TypeaheadProps } from './types';

export const TypeaheadNative = <T extends string | TypeaheadBaseItem>(
  props: TypeaheadProps<T>,
) => {
  return <TypeaheadNativeProvider {...props} />;
};

const TypeaheadNativeProvider = <T extends string | TypeaheadBaseItem>(
  props: TypeaheadProps<T>,
) => {
  const providerValue = React.useMemo(() => new TypeaheadService(), [props]);
  return (
    <TypeaheadContext.Provider value={providerValue}>
      <TypeaheadNativeComponent {...props} />
    </TypeaheadContext.Provider>
  );
};

const TypeaheadNativeComponent = <T extends string | TypeaheadBaseItem>({
  label,
  options,
  defaultValue,
  emptyValue,
  hintMessage,
  disabled = false,
  multiple = false,
  onChange = (value: T[]) => {},
  onInputChange = (value: NativeSyntheticEvent<TextInputChangeEventData>) => {},
  asyncOptions,
  getOptionText = (option) =>
    (option as TypeaheadBaseItem).text ?? (option as string),
  getOptionValue = (option) =>
    (option as TypeaheadBaseItem).value ?? (option as string),
}: TypeaheadProps<T>) => {
  const theme = useTheme();
  const styles = useStyles();

  // initializations
  const sheetRef = React.useRef<BottomSheetModal>(null);
  const [selectedValues, setSelectedValues] = React.useState(
    uniqueWithMap(defaultValue, getOptionValue) as T[],
  );
  const handleOpenBottomSheet = () => {
    sheetRef.current?.present();
  };

  const TypeaheadNativeContext = React.useContext(TypeaheadContext);
  React.useEffect(() => {
    const newValues = uniqueWithMap(defaultValue, getOptionValue) as T[];
    setSelectedValues(newValues);
    TypeaheadNativeContext.setSelectedItems(newValues);
  }, [defaultValue]);

  // actions
  const handleBottomSheetDismiss = () => {
    sheetRef.current?.dismiss();
    const selectedItems = TypeaheadNativeContext.getSelectedItems();
    setSelectedValues(selectedItems);
    onChange(selectedItems);
  };

  const handleReturnKeyPressed = () => {
    // wait for keyboard to dismiss and then dismiss the bottomsheet
    Keyboard.addListener('keyboardDidHide', () => {
      Keyboard.removeAllListeners('keyboardDidHide');
      setTimeout(
        () => {
          handleBottomSheetDismiss();
        },
        Platform.OS == 'android' ? 100 : 0,
      );
    });
    Keyboard.dismiss();
  };

  return (
    <View>
      <View>
        {label && (
          <Text
            testID={TypeaheadNativeTestIDs.label}
            style={{ marginBottom: theme.getSpacing(1) }}
          >
            {label}
          </Text>
        )}
        <Pressable onPress={handleOpenBottomSheet} style={styles.pressable}>
          <View style={{ flexDirection: 'row' }}>
            <Text
              style={styles.pressableLabel}
              ellipsizeMode="tail"
              numberOfLines={1}
            >
              {selectedValues.map((x) => getOptionText(x)).join(', ') ||
                hintMessage}
            </Text>
          </View>
        </Pressable>
      </View>
      <BottomSheet
        bottomSheetRef={sheetRef}
        height={'90%'}
        onDismiss={handleBottomSheetDismiss}
      >
        <TypeaheadNativeBase
          options={options}
          defaultValue={selectedValues}
          emptyValue={emptyValue}
          hintMessage={hintMessage}
          disabled={disabled}
          multiple={multiple}
          onInputChange={onInputChange}
          asyncOptions={asyncOptions}
          getOptionText={(option) => getOptionText(option as T)}
          getOptionValue={(option) => getOptionValue(option as T)}
          onReturnKeyPressed={handleReturnKeyPressed}
          onClose={handleBottomSheetDismiss}
          context={TypeaheadNativeContext}
        />
      </BottomSheet>
    </View>
  );
};

const useStyles = makeStyles((theme) => ({
  pressable: {
    height: 50,
    borderRadius: 6,
    borderWidth: 1,
    borderColor: theme.palette.gray[500],
  },
  pressableLabel: {
    paddingTop: theme.getSpacing(2),
    paddingLeft: theme.getSpacing(3),
    paddingRight: theme.getSpacing(3),
    fontSize: 16,
    color: theme.palette.gray[500],
    flex: 6,
  },
}));

export const TypeaheadNativeTestIDs = {
  label: 'TypeaheadNative-label',
};
