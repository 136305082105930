import { AxiosRequestConfig } from 'axios';
import { BaseApiConfiguration, BaseApiService } from 'assets/core/api';
import {
  AppointmentTypeScheduleDto,
  ServiceApi as AppointmentServiceClient,
  ServiceScheduleApi as ScheduleServiceClient,
  ServiceTypeApi as TypeServiceClient,
  ServiceBookingsApi as BookingsServiceClient,
  AppointmentTypeDto,
  AppointmentGroupDto,
  CreateAppointmentTypeDto,
  UpdateAppointmentTypeDto,
  CreateAppointmentTypeScheduleDto,
  UpdateAppointmentTypeScheduleDto,
  AppointmentTypeSlotDto,
  CreateBookingDto,
  BookingDto,
  UpdateBookingDto,
  BookingPageDto,
} from '@digitalpharmacist/appointment-service-client-axios';
import { APPOINTMENT_API_BASE_URL } from '../common/constants';
import { PaginatedRemoteDatasource } from 'assets/components/data-grid/data-grid-toolkit/datasource/PaginatedRemoteDatasource';
import { useAppStateStore } from '../store/app-store';
import { AppointmentsFindParams } from '../schedule/appointments-list/appointments-list.utils';

export interface IAppointmentService {}

export class AppointmentService
  extends BaseApiService
  implements IAppointmentService
{
  private appointmentServiceClient: AppointmentServiceClient;
  private scheduleServiceClient: ScheduleServiceClient;
  private typeServiceClient: TypeServiceClient;
  private bookingsServiceClient: BookingsServiceClient;

  public getAppointmentServiceUrl: string;

  constructor(
    baseUrl: string,
    config: AxiosRequestConfig = {},
    enableAuth = true,
    baseConfig?: BaseApiConfiguration,
  ) {
    super(baseUrl, config, enableAuth, baseConfig);

    this.appointmentServiceClient = new AppointmentServiceClient(
      undefined,
      baseUrl,
      this.axiosInstance,
    );

    this.scheduleServiceClient = new ScheduleServiceClient(
      undefined,
      baseUrl,
      this.axiosInstance,
    );

    this.typeServiceClient = new TypeServiceClient(
      undefined,
      baseUrl,
      this.axiosInstance,
    );

    this.bookingsServiceClient = new BookingsServiceClient(
      undefined,
      baseUrl,
      this.axiosInstance,
    );

    this.getAppointmentServiceUrl = `${baseUrl}/appointment`;
  }

  getAppointmentsPaginatedDatasource() {
    const current_location_id = useAppStateStore.getState().locationId;
    const current_pharmacy_id = useAppStateStore.getState().pharmacyId;

    return new PaginatedRemoteDatasource(
      `${APPOINTMENT_API_BASE_URL}/pharmacies/${current_pharmacy_id}/bookings?location_id=${current_location_id}`,
      this.axiosInstance,
    );
  }

  async findAppointmentTypes(
    pharmacyId: string,
    locationId: string,
    withDisabled?: boolean,
  ): Promise<AppointmentTypeDto[]> {
    const { data } = await this.appointmentServiceClient.appointmentTypeFind(
      pharmacyId,
      locationId,
      withDisabled,
    );

    return data;
  }

  async findAppointmentTypeById(
    pharmacyId: string,
    locationId: string,
    appointmentId: string,
  ): Promise<AppointmentTypeDto> {
    const { data } = await this.appointmentServiceClient.appointmentTypeFindOne(
      pharmacyId,
      locationId,
      appointmentId,
    );

    return data;
  }

  async createAppointmentType(
    pharmacyId: string,
    locationId: string,
    appointmentType: CreateAppointmentTypeDto,
  ): Promise<AppointmentTypeDto> {
    const { data } = await this.appointmentServiceClient.appointmentTypeCreate(
      pharmacyId,
      locationId,
      appointmentType,
    );

    return data;
  }

  async updateAppointmentType(
    pharmacyId: string,
    locationId: string,
    appointmentId: string,
    appointmentType: UpdateAppointmentTypeDto,
  ): Promise<AppointmentTypeDto> {
    const { data } = await this.appointmentServiceClient.appointmentTypeUpdate(
      pharmacyId,
      locationId,
      appointmentId,
      appointmentType,
    );

    return data;
  }

  async deleteAppointmentType(
    pharmacyId: string,
    locationId: string,
    appointmentId: string,
  ): Promise<AppointmentTypeDto> {
    const { data } = await this.appointmentServiceClient.appointmentTypeRemove(
      pharmacyId,
      locationId,
      appointmentId,
    );

    return data;
  }

  async findAppointmentTypeSchedules(
    pharmacyId: string,
    locationId: string,
  ): Promise<AppointmentTypeScheduleDto[]> {
    const { data } =
      await this.scheduleServiceClient.appointmentTypeScheduleFind(
        pharmacyId,
        locationId,
      );

    return data;
  }

  async createAppointmentTypeSchedule(
    pharmacyId: string,
    locationId: string,
    schedule: CreateAppointmentTypeScheduleDto,
  ): Promise<AppointmentTypeScheduleDto> {
    const { data } =
      await this.scheduleServiceClient.appointmentTypeScheduleCreate(
        pharmacyId,
        locationId,
        schedule,
      );

    return data;
  }

  async getAppointmentTypeScheduleById(
    pharmacyId: string,
    locationId: string,
    scheduleId: string,
  ): Promise<AppointmentTypeScheduleDto> {
    const { data } =
      await this.scheduleServiceClient.appointmentTypeScheduleFindOne(
        pharmacyId,
        locationId,
        scheduleId,
      );

    return data;
  }

  async updateAppointmentTypeSchedule(
    pharmacyId: string,
    locationId: string,
    scheduleId: string,
    schedule: UpdateAppointmentTypeScheduleDto,
  ): Promise<AppointmentTypeScheduleDto> {
    const { data } =
      await this.scheduleServiceClient.appointmentTypeScheduleUpdate(
        pharmacyId,
        locationId,
        scheduleId,
        schedule,
      );

    return data;
  }

  async deleteAppointmentTypeSchedule(
    pharmacyId: string,
    locationId: string,
    scheduleId: string,
  ): Promise<AppointmentTypeScheduleDto> {
    const { data } =
      await this.scheduleServiceClient.appointmentTypeScheduleRemove(
        pharmacyId,
        locationId,
        scheduleId,
      );

    return data;
  }

  async findAppointmentGroups(
    pharmacyId: string,
  ): Promise<AppointmentGroupDto[]> {
    const { data } =
      await this.typeServiceClient.appointmentGroupFind(pharmacyId);

    return data;
  }

  async getAvailableAppointmentSlots(
    pharmacyId: string,
    locationId: string,
    appointmentTypeId: string,
    startDate: string,
    endDate: string,
  ): Promise<AppointmentTypeSlotDto[]> {
    const { data } =
      await this.appointmentServiceClient.appointmentTypeGetAvailableSlots(
        pharmacyId,
        locationId,
        appointmentTypeId,
        startDate,
        endDate,
      );

    return data;
  }

  async createBooking(
    pharmacyId: string,
    locationId: string,
    booking: CreateBookingDto,
  ): Promise<BookingDto> {
    const { data } = await this.bookingsServiceClient.bookingCreate(
      pharmacyId,
      locationId,
      booking,
    );
    return data;
  }

  async getBooking(
    pharmacyId: string,
    locationId: string,
    bookingId: string,
  ): Promise<BookingDto> {
    const { data } = await this.bookingsServiceClient.bookingFindOne(
      pharmacyId,
      locationId,
      bookingId,
    );

    return data;
  }

  async getBookings(params: AppointmentsFindParams): Promise<BookingPageDto> {
    const { data } = await this.bookingsServiceClient.bookingFindFind(
      params.pharmacyId,
      params.offset,
      params.limit,
      params.orderBy,
      params.locationId,
      params.appointmentTypeId,
      params.patientUserId,
      params.minStartDate,
      params.maxStartDate,
      params.minEndDate,
      params.maxEndDate,
      params.status,
    );

    return data;
  }

  async updateBooking(
    pharmacyId: string,
    locationId: string,
    bookingId: string,
    booking: UpdateBookingDto,
  ): Promise<BookingDto> {
    const { data } = await this.bookingsServiceClient.bookingUpdate(
      pharmacyId,
      locationId,
      bookingId,
      booking,
    );

    return data;
  }

  async cancelBooking(
    pharmacyId: string,
    locationId: string,
    bookingId: string,
  ): Promise<BookingDto> {
    const { data } = await this.bookingsServiceClient.bookingCancel(
      pharmacyId,
      locationId,
      bookingId,
    );

    return data;
  }
}

export default new AppointmentService(
  APPOINTMENT_API_BASE_URL,
  undefined,
  true,
);
