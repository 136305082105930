import {
  BookingStatus,
  SortOrder,
} from '@digitalpharmacist/appointment-service-client-axios';
import moment from 'moment';
import theme, { useTheme } from '../../../../../packages/assets/theme';
import {
  CheckIcon,
  AlertTriangleIcon,
} from '../../../../../packages/assets/icons';
import { getText } from 'assets/localization/localization';

export const STATUS_STYLE_MAPPING = {
  CANCELLED: {
    color: theme.palette.error[700],
    backgroundColor: theme.palette.error[100],
    label: getText('canceled'),
  },
  ACCEPTED: {
    color: theme.palette.success[700],
    backgroundColor: theme.palette.success[100],
    label: getText('not-started'),
  },
  REJECTED: {
    color: theme.palette.warning[700],
    backgroundColor: theme.palette.warning[100],
    label: getText('rejected'),
  },
  PENDING: {
    color: theme.palette.primary[700],
    backgroundColor: theme.palette.primary[100],
    label: getText('pending'),
  },
};

export const minutesDifference = (endTime: string, startTime: string) =>
  moment(endTime).diff(moment(startTime), 'minute');

export interface AppointmentsFindParams {
  pharmacyId: string;
  offset: number;
  limit: number;
  orderBy: SortOrder;
  locationId?: string;
  appointmentTypeId?: string;
  patientUserId?: string;
  minStartDate?: string;
  maxStartDate?: string;
  minEndDate?: string;
  maxEndDate?: string;
  status?: BookingStatus;
}

export const calculateFormIconTooltip = (
  status: string,
  isSubmitted: boolean,
) => {
  const theme = useTheme();
  if (status !== 'ENABLED' && !isSubmitted) {
    return {
      icon: undefined,
      iconColor: theme.palette.error[500],
      tooltip: getText('form-unavailable'),
    };
  }

  if (status !== 'ENABLED' && isSubmitted) {
    return {
      icon: CheckIcon,
      iconColor: theme.palette.success[600],
      tooltip: getText('form-unavailable'),
    };
  }

  if (status === 'ENABLED' && !isSubmitted) {
    return {
      icon: AlertTriangleIcon,
      iconColor: theme.palette.error[500],
      tooltip: getText('form-not-started'),
    };
  }
  return {
    icon: CheckIcon,
    iconColor: theme.palette.success[600],
    tooltip: getText('form-submitted'),
  };
};
